import React, { Suspense, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.scss";
import routes from "./routes/routes";
import NavFooter from "./containers/footer/NavFooter";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import interceptors from "./interceptors";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Review from "./containers/eventreviews/Reviews";
import useWindowDimensions from "./hooks/useWindowDimensions";
import Loader from "./components/loaders/Loader";
import { history } from "./store";
import { validateUser } from "./api/userApi";
import { ERROR, PENDING } from "./constants/statusTypes";
import ErrorPage from "./components/errorpages/ErrorPage";

library.add(fas, fab);
interceptors.setupInterceptors(history);

const loading = () => <Loader />;

function App() {
  const { elementHeight, elementWidth } = useWindowDimensions();
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);

  useEffect(() => {
    if (
      (user.status === ERROR || user.status === PENDING) &&
      user.attempt < 1
    ) {
      validateUser(dispatch);
    }
  }, [dispatch, user]);

  return (
    <Router>
      {user.status === ERROR ? (
        <ErrorPage />
      ) : (
        <Fragment>
          <div
            className="containment"
            style={{ height: elementHeight, width: elementWidth }}
          >
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, id) => {
                  return route.component ? (
                    <Route
                      key={id}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
              </Switch>
            </Suspense>
            <NavFooter />
          </div>

          <Review />
        </Fragment>
      )}
    </Router>
  );
}

export default App;
