import axios from "axios";
import { getToken } from "./adalConfig";

export default {
  setupInterceptors: history => {
    axios.interceptors.request.use(config => {
      config.headers = {
        Authorization: "Bearer " + getToken()
      };

      return config;
    });

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (401 === error.response.status) {
          window.location.reload();
        }
        if (403 === error.response.status) {
          history.push("forbidden");
        }
      }
    );
  }
};
