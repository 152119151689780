import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { runWithAdal } from "react-adal";
import { authContext } from "./adalConfig";
import reportWebVitals from "./reportWebVitals";

const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
  },
  DO_NOT_LOGIN
);

reportWebVitals();
