import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const DefaultButton = ({
  color,
  fn,
  icon,
  text,
  disabled,
  iconPrefix,
  style
}) => {
  const prefix = iconPrefix ? iconPrefix : "fas";
  return (
    <Button
      style={style}
      disabled={disabled}
      xs="12"
      color={color || "#05bacf"}
      className="accept-button"
      onClick={() => {
        fn();
      }}
    >
      {icon ? <FontAwesomeIcon style={{marginRight: "0.7rem"}} color={"#fff"} icon={[prefix, icon]} /> : null}
      {text}
    </Button>
  );
};

export default DefaultButton;
