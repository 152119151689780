import * as actions from "../actions/userActions";
import { ValidateUser } from "../constants/apiConstants";
import { postData } from "./api";

export function validateUser(dispatch) {
    postData({
      dispatch,
      startActionType: actions.userValidateStarted,
      successActionType: actions.userValidateSuccess,
      errorActionType: actions.userValidateFailed,
      url: ValidateUser,
      data: null
    });
  }
