export const DEVICE_CODE_EXPIRED = "DEVICE_CODE_EXPIRED";
export const DEVICE_CODE_FETCHED = "DEVICE_CODE_FETCHED";
export const USER_CODE_FETCHED = "USER_CODE_FETCHED";
export const USER_CODE_REFRESH = "USER_CODE_REFRESH";
export const USER_CODE_ERROR = "USER_CODE_ERROR";
export const DEVICE_CODE_ERROR = "DEVICE_CODE_ERROR";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_IN_ERROR = "LOGGED_IN_ERROR";
export const EMAIL_CONFIRMED = "EMAIL_CONFIRMED";
export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_CONFIRMED_ERROR = "EMAIL_CONFIRMED_ERROR";
export const EMAIL_STARTED = "EMAIL_STARTED";
export const EMAIL_ERROR = "EMAIL_ERROR";
export const MESSAGE_ADDED = "MESSAGE_ADDED";
export const RESET_USER_INPUT = "RESET_USER_INPUT";

export const loggedInSuccess = dispatch => {
  dispatch({
    type: LOGGED_IN
  });
};

export const loggedInError = dispatch => {
  dispatch({
    type: LOGGED_IN_ERROR
  });
};

export const userCodeFetched = (dispatch, userCode) => {
  dispatch({
    type: USER_CODE_FETCHED,
    userCode: userCode
  });
};

export const deviceCodeFetched = (dispatch, deviceCode) => {
  dispatch({
    type: DEVICE_CODE_FETCHED,
    deviceCode: deviceCode
  });
};

export const deviceCodeExpired = dispatch => {
  dispatch({
    type: DEVICE_CODE_EXPIRED
  });
};

export const deviceCodeError = dispatch => {
  dispatch({
    type: DEVICE_CODE_ERROR
  });
};

export const userCodeRefresh = dispatch => {
  dispatch({
    type: USER_CODE_REFRESH
  });
};

export const userCodeError = dispatch => {
  dispatch({
    type: USER_CODE_ERROR
  });
};

export const emailConfirmed = dispatch => {
  dispatch({
    type: EMAIL_CONFIRMED
  });
};

export const emailSent = () => {
  return {
    type: EMAIL_SENT
  };
};

export const emailStarted = () => {
  return {
    type: EMAIL_STARTED
  };
};

export const emailError = () => {
  return {
    type: EMAIL_ERROR
  };
};

export const emailConfirmedError = dispatch => {
  dispatch({
    type: EMAIL_CONFIRMED_ERROR
  });
};

export const messageAdded = (dispatch, message) => {
  dispatch({
    type: MESSAGE_ADDED,
    message: message
  });
};

export const resetUserInput = dispatch => {
  dispatch({
    type: RESET_USER_INPUT
  });
};
