import React from "react";
import FooterNavButton from "../../components/buttons/FooterNavButton";
import FooterLogoutButton from "../../components/buttons/FooterLogoutButton";
import { authContext } from "../../adalConfig";

const logout = () => {
  authContext.logOut();
};

const NavFooter = () => {
  const platfrom = navigator.platform;
  const safariMargin = platfrom === "iPhone" ? "15px" : "0";
  const style = {
    paddingBottom: safariMargin
  };
  return (
    <div className="footer-navigation" style={style}>
      <FooterNavButton route={"/events"} name="Events" icon="home" />
      <FooterNavButton route={"/myevents"} name="Mijn Events" icon="list-ul" />
      <FooterNavButton route={"/Settings"} name="Settings" icon="cogs" />
      <FooterLogoutButton fn={logout} name="Log out" />
    </div>
  );
};

export default NavFooter;
