import { getData, postChained } from "./api";
import {
  eventReviewFetchStarted,
  eventReviewFetchSuccess,
  eventReviewFetchFailed,
  eventReviewsFetchStarted,
  eventReviewsFetchSuccess,
  eventReviewsFetchFailed,
  postEventReviewSuccess,
  postEventReviewStarted,
  postEventReviewFailed,
  postEventReviewWithoutError
} from "../actions/modalAction";
import {
  GetEventReviews,
  PostEventReview,
  GetEventReview
} from "../constants/apiConstants";

export function getEventReviews(dispatch) {
  getData({
    dispatch,
    startActionType: eventReviewsFetchStarted,
    successActionType: eventReviewsFetchSuccess,
    errorActionType: eventReviewsFetchFailed,
    url: GetEventReviews
  });
}

export function postEventReview(dispatch, eventReview) {
  postChained({
    dispatch,
    startActionType: postEventReviewSuccess,
    successActionType: postEventReviewStarted,
    errorActionType: postEventReviewFailed,
    afterActionType: postEventReviewWithoutError,
    url: PostEventReview,
    data: eventReview
  });
}

export function getEventReview(dispatch, eventId) {
  getData({
    dispatch,
    startActionType: eventReviewFetchStarted,
    successActionType: eventReviewFetchSuccess,
    errorActionType: eventReviewFetchFailed,
    url: `${GetEventReview}${eventId}`
  });
}
