export const MODAL_CHANGED = "EVENTREVIEWS_FETCH_ERROR";
export const EVENT_REVIEW_FETCH_ERROR = "EVENT_REVIEW_FETCH_ERROR";
export const EVENT_REVIEW_FETCH_SUCCESS = "EVENT_REVIEW_FETCH_SUCCESS";
export const EVENT_REVIEW_FETCH_START = "EVENT_REVIEW_FETCH_START";
export const EVENT_REVIEWS_FETCH_ERROR = "EVENT_REVIEWS_FETCH_ERROR";
export const EVENT_REVIEWS_FETCH_SUCCESS = "EVENT_REVIEWS_FETCH_SUCCESS";
export const EVENT_REVIEWS_FETCH_START = "EVENT_REVIEW_FETCH_START";
export const EVENT_REVIEW_POST_SUCCESS = "EVENT_REVIEW_POST_SUCCESS";
export const EVENT_REVIEW_POST_ERROR = "EVENT_REVIEW_POST_ERROR";
export const EVENT_REVIEW_POST_START = "EVENT_REVIEW_POST_START";
export const EVENT_REVIEW_POST_WITHOUT_ERROR ="EVENT_REVIEW_POST_WITHOUT_ERROR";
export const EVENT_REVIEW_PUT_SUCCESS = "EVENT_REVIEW_PUT_SUCCESS";
export const EVENT_REVIEW_PUT_ERROR = "EVENT_REVIEW_PUT_ERROR";
export const EVENT_REVIEW_PUT_START = "EVENT_REVIEW_PUT_START";
export const EVENT_REVIEW_PUT_WITHOUT_ERROR = "EVENT_REVIEW_PUT_WITHOUT_ERROR";
export const EVENT_REVIEW_REFRESH_STATE = "EVENT_REVIEW_REFRESH_STATE";

export const changeModalState = () => {
  return {
    type: MODAL_CHANGED
  };
};

export const eventReviewFetchStarted = () => {
  return {
    type: EVENT_REVIEW_FETCH_START
  };
};

export const eventReviewFetchFailed = () => {
  return {
    type: EVENT_REVIEW_FETCH_ERROR
  };
};

export const eventReviewFetchSuccess = eventReview => {
  return {
    type: EVENT_REVIEW_FETCH_SUCCESS,
    eventReview: eventReview
  };
};

export const eventReviewsFetchStarted = () => {
  return {
    type: EVENT_REVIEWS_FETCH_START
  };
};

export const eventReviewsFetchFailed = () => {
  return {
    type: EVENT_REVIEWS_FETCH_ERROR
  };
};

export const eventReviewsFetchSuccess = eventReviews => {
  return {
    type: EVENT_REVIEWS_FETCH_SUCCESS,
    eventReviews: eventReviews
  };
};

export const postEventReviewSuccess = postedEventReview => {
  return {
    type: EVENT_REVIEW_POST_SUCCESS,
    postedEventReview: postedEventReview
  };
};

export const postEventReviewStarted = () => {
  return {
    type: EVENT_REVIEW_POST_START
  };
};

export const postEventReviewFailed = () => {
  return {
    type: EVENT_REVIEW_POST_ERROR
  };
};

export const postEventReviewWithoutError = () => {
  return {
    type: EVENT_REVIEW_POST_WITHOUT_ERROR
  };
};

export const eventReviewRefreshState = dispatch => {
  dispatch({
    type: EVENT_REVIEW_REFRESH_STATE
  });
};
