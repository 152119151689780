import * as actions from "../actions/modalAction";
import { PENDING, LOADED, LOADING, ERROR } from "../constants/statusTypes";

export const initialState = {
  eventReview: { status: PENDING, data: null },
  eventReviews: { status: PENDING, data: null },
  postedEventReview: { status: PENDING, data: {}, postedWithoutError: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
      case actions.EVENT_REVIEW_FETCH_START:
        return { ...state, eventReview: { status: LOADING } };
      case actions.EVENT_REVIEW_FETCH_SUCCESS:
        return { ...state, eventReview: { status: LOADED, data: action.eventReview } };
      case actions.EVENT_REVIEW_FETCH_ERROR:
        return { ...state, eventReview: { status: ERROR } };
      case actions.EVENT_REVIEW_REFRESH_STATE:
        return { ...state, eventReview: { status: PENDING, data: null}}
      case actions.EVENT_REVIEWS_FETCH_START:
        return { ...state, eventReviews: { status: LOADING } };
      case actions.EVENT_REVIEWS_FETCH_SUCCESS:
        return { ...state, eventReviews: { status: LOADED, data: action.eventReviews } };
      case actions.EVENT_REVIEWS_FETCH_ERROR:
        return { ...state, eventReviews: { status: ERROR } };
      case actions.EVENT_REVIEW_POST_START:
        return { ...state, postedEventReview: { status: LOADING } };
      case actions.EVENT_REVIEW_POST_SUCCESS:
        return {...state, postedEventReview: { status: LOADED, data: action.postedEventReview }};
      case actions.EVENT_REVIEW_POST_ERROR:
        return { ...state, postedEventReview: { status: ERROR } };
      case actions.EVENT_REVIEW_POST_WITHOUT_ERROR:
        return {...state, postedEventReview: { status: LOADED, postedWithoutError: true}};
      default:
        return state;
  }
};
