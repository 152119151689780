import React from "react";

const NotificationPage = ({
  children,
  heading,
  text,
  navigate,
  buttonText,
  buttonClass
}) => {
    buttonClass= buttonClass? buttonClass : "fa fa-arrow-right";
  return (
    <div className="error-page">
      {children}
      <h1>{heading}</h1>
      <p>{text}</p>
      <div className="button-holder">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate()}
        >
          {buttonText}
          <span className={buttonClass} />
        </button>
      </div>
    </div>
  );
};

export default NotificationPage;
