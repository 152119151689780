import { PENDING, LOADED, LOADING, ERROR } from "../constants/statusTypes";
import * as actions from "../actions/eventsAction";

export const initialState = {
  event: { status: PENDING, data: null },
  postedEvent: { status: PENDING, data: {} },
  addToCalendar: { status: PENDING, data: {} },
  eventDetails: { status: PENDING, data: null }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.EVENT_FETCH_START:
      return { ...state, event: { status: LOADING } };
    case actions.EVENT_FETCH_SUCCESS:
      return { ...state, event: { status: LOADED, data: action.event } };
    case actions.EVENT_FETCH_ERROR:
      return { ...state, event: { status: ERROR } };
    case actions.EVENT_REFRESH_STATE:
      return { ...state, event: { status: PENDING, data: null }};  
    case actions.EVENT_POST_START:
      return { ...state, postedEvent: { status: LOADING } };
    case actions.EVENT_POST_SUCCESS:
      return {...state, postedEvent: { status: LOADED, data: action.postedEvent }};
    case actions.EVENT_POST_ERROR:
      return { ...state, postedEvent: { status: ERROR } };
    case actions.EVENT_DETAILS_FETCH_START:
      return { ...state, eventDetails: { status: LOADING } };
    case actions.EVENT_DETAILS_FETCH_SUCCESS:
      return { ...state, eventDetails: { status: LOADED, data: action.eventDetails } };
    case actions.EVENT_DETAILS_FETCH_ERROR:
      return { ...state, eventDetails: { status: ERROR } };
    case actions.EVENT_DETAILS_REFRESH_STATE:
      return { ...state, eventDetails: { status: PENDING, data: null }};  
      case actions.ADD_TO_CALENDAR_FETCH_START:
        return { ...state, addToCalendar: { status: LOADING } };
      case actions.ADD_TO_CALENDAR_FETCH_SUCCESS:
        return { ...state, addToCalendar: { status: LOADED, data: action.addToCalendar } };
      case actions.ADD_TO_CALENDAR_FETCH_ERROR:
        return { ...state, addToCalendar: { status: ERROR } };
      case actions.ADD_TO_CALENDAR_REFRESH_STATE:
        return { ...state, addToCalendar: { status: PENDING, data: null }};  
    default:
      return state;
  }
};
