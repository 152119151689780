import React, { useEffect, Fragment } from "react";
import EventReviews from "../../components/modal/EventReviews";
import { useSelector, useDispatch } from "react-redux";
import { getEventReviews } from "../../api/eventReviewApi";
import { LOADED, LOADING, ERROR } from "../../constants/statusTypes";

const Reviews = () => {
  const eventReviews = useSelector(
    state => state.eventReviewReducer.eventReviews
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getEventReviews(dispatch);
  }, [dispatch]);

  if (eventReviews == null) return null;
  return eventReviews &&
    eventReviews.status === LOADING ? null : eventReviews.status ===
    ERROR ? null : eventReviews.status === LOADED ? (
    <Fragment>
      {eventReviews.data.map(review => {
        return <EventReviews review={review} key={review.eventID} />;
      })}
    </Fragment>
  ) : null;
};

export default Reviews;
