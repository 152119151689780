import { PENDING, LOADED, ERROR, LOADING } from "../constants/statusTypes";
import * as actions from "../actions/userActions";

export const initialState = {
  user: { status: PENDING, data: null, attempt: 0 }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_VALIDATE_START:
      return {
        ...state,
        user: { status: LOADING, data: null, attempt: state.user.attempt }
      };
    case actions.USER_VALIDATE_SUCCESS:
      return {
        ...state,
        user: { status: LOADED, data: action.user, attempt: 0 }
      };
    case actions.USER_VALIDATE_ERROR:
      return {
        ...state,
        user: { status: ERROR, data: null, attempt: state.user.attempt + 1 }
      };
    case actions.USER_RESET:
      return {
        ...state,
        user: { status: PENDING, data: null, attempt: 0 }
      };
    default:
      return state;
  }
};
