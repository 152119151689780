export const EVENTS_FETCH_ERROR = "EVENTS_FETCH_ERROR";
export const EVENTS_FETCH_SUCCESS = "EVENTS_FETCH_SUCCESS";
export const EVENTS_FETCH_START = "EVENTS_FETCH_START";
export const EVENT_FETCH_ERROR = "EVENT_FETCH_ERROR";
export const EVENT_FETCH_SUCCESS = "EVENT_FETCH_SUCCESS";
export const EVENT_FETCH_START = "EVENT_FETCH_START";
export const EVENT_REFRESH_STATE = "EVENT_REFRESH_STATE";
export const EVENT_POST_SUCCESS = "EVENTS_POST_SUCCESS";
export const EVENT_POST_ERROR = "EVENTS_POST_ERROR";
export const EVENT_POST_START = "EVENTS_POST_START";
export const MY_EVENTS_FETCH_ERROR = "MY_EVENTS_FETCH_ERROR";
export const MY_EVENTS_FETCH_SUCCESS = "MY_EVENTS_FETCH_SUCCESS";
export const MY_EVENTS_FETCH_START = "MY_EVENTS_FETCH_START";
export const EVENT_DETAILS_FETCH_ERROR = "EVENT_DETAILS_FETCH_ERROR";
export const EVENT_DETAILS_FETCH_SUCCESS = "EVENT_DETAILS_FETCH_SUCCESS";
export const EVENT_DETAILS_FETCH_START = "EVENT_DETAILS_FETCH_START";
export const EVENT_DETAILS_REFRESH_STATE = "EVENT_DETAILS_REFRESH_STATE";
export const ADD_TO_CALENDAR_REFRESH_STATE = "ADD_TO_CALENDAR_REFRESH_STATE";
export const ADD_TO_CALENDAR_FETCH_ERROR = "ADD_TO_CALENDAR_FETCH_ERROR";
export const ADD_TO_CALENDAR_FETCH_SUCCESS = "ADD_TO_CALENDAR_FETCH_SUCCESS";
export const ADD_TO_CALENDAR_FETCH_START = "ADD_TO_CALENDAR_FETCH_START";

export const fetchStarted = () => {
  return {
    type: EVENTS_FETCH_START
  };
};

export const fetchFailed = () => {
  return {
    type: EVENTS_FETCH_ERROR
  };
};

export const fetchSuccess = events => {
  return {
    type: EVENTS_FETCH_SUCCESS,
    events: events
  };
};

export const eventFetchStarted = () => {
  return {
    type: EVENT_FETCH_START
  };
};

export const eventFetchFailed = () => {
  return {
    type: EVENT_FETCH_ERROR
  };
};

export const eventFetchSuccess = event => {
  return {
    type: EVENT_FETCH_SUCCESS,
    event: event
  };
};

export const postSuccess = postedEvent => {
  return {
    type: EVENT_POST_SUCCESS,
    postedEvent: postedEvent
  };
};

export const postStarted = () => {
  return {
    type: EVENT_POST_START
  };
};

export const postFailed = () => {
  return {
    type: EVENT_POST_ERROR
  };
};

export const myFetchStarted = () => {
  return {
    type: MY_EVENTS_FETCH_START
  };
};

export const myFetchFailed = () => {
  return {
    type: MY_EVENTS_FETCH_ERROR
  };
};

export const myFetchSuccess = myEvents => {
  return {
    type: MY_EVENTS_FETCH_SUCCESS,
    myEvents: myEvents
  };
};

export const eventDetailsFetchStarted = () => {
  return {
    type: EVENT_DETAILS_FETCH_START
  };
};

export const eventDetailsFetchFailed = () => {
  return {
    type: EVENT_DETAILS_FETCH_ERROR
  };
};

export const eventDetailsFetchSuccess = eventDetails => {
  return {
    type: EVENT_DETAILS_FETCH_SUCCESS,
    eventDetails: eventDetails
  };
};

export const eventRefreshState = dispatch => {
  dispatch({
    type: EVENT_REFRESH_STATE
  });
};

export const eventDetailsRefreshState = dispatch => {
  dispatch({
    type: EVENT_DETAILS_REFRESH_STATE
  });
};

export const addToCalendarFetchStarted = () => {
  return {
    type: ADD_TO_CALENDAR_FETCH_START
  };
};

export const addToCalendarFetchFailed = () => {
  return {
    type: ADD_TO_CALENDAR_FETCH_ERROR
  };
};

export const addToCalendarFetchSuccess = addToCalendar => {
  return {
    type: ADD_TO_CALENDAR_FETCH_SUCCESS,
    addToCalendar: addToCalendar
  };
};

export const addToCalendarRefreshState = dispatch => {
  dispatch({
    type: ADD_TO_CALENDAR_REFRESH_STATE
  });
};
