import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToasterError = ({ toggle, text }) => {
  const [show, setShow] = useState(toggle);

  useEffect(() => {
    setShow(toggle);
  }, [toggle]);

  return (
    <Toast
      className="toaster-error"
      autohide
      style={{ width: "100%" }}
      show={show}
      onClose={() => setShow(false)}
    >
      <Toast.Header className="toaster-error">
        <FontAwesomeIcon color={"#fff"} icon={["fas", "times"]} />
        {text === undefined ? (
          <strong className="mr-auto">Something went wrong</strong>
        ) : (
          <strong className="mr-auto">{text}</strong>
        )}
      </Toast.Header>
    </Toast>
  );
};

export default ToasterError;
