import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import DefaultButton from "../buttons/DefaultButton";
import ToasterSuccess from "../toasters/ToasterSuccess";
import ToasterError from "../toasters/ToasterError";
import { postEventReview } from "../../api/eventReviewApi";
import { LOADED, ERROR } from "../../constants/statusTypes";
import ToasterWrapper from "../toasters/ToasterWrapper";

const EventReviews = ({ review }) => {
  const postedEventReview = useSelector(
    state => state.eventReviewReducer.postedEventReview
  );
  const [rating, setRaing] = useState(0);
  const [remark, setRemark] = useState("");
  const [isOpen, setIsopen] = useState(true);
  const [toasterSuccess, setToasterSuccess] = useState(false);
  const [toasterError, setToasterError] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const dispatch = useDispatch();
  const toggleModalState = () => {
    setIsopen(!isOpen);
  };

  const postUserEventReview = () => {
    const postData = {
      eventid: review.eventID,
      eventname: review.eventName,
      remark: remark,
      score: rating,
      status: 1
    };

    postEventReview(dispatch, postData);
    setDataFlag(true);
  };

  useEffect(() => {
    let successId, errorId;
    if (
      dataFlag &&
      postedEventReview.status === LOADED &&
      postedEventReview.postedWithoutError
    ) {
      setToasterSuccess(true);
      setIsopen(false);
      successId = setTimeout(() => {
        setToasterSuccess(false);
      }, 2000);
    }
    if (dataFlag && postedEventReview.status === ERROR) {
      setToasterError(true);
      errorId = setTimeout(() => {
        setToasterError(false);
      }, 2000);
    }

    return () => {
      clearTimeout(successId);
      clearTimeout(errorId);
    };
  }, [
    postedEventReview.status,
    dataFlag,
    postedEventReview.postedWithoutError
  ]);

  return (
    <Fragment>
      <ToasterWrapper>
        {toasterSuccess ? (
          <ToasterSuccess text={"Changes saved"} toggle={toasterSuccess} />
        ) : null}
        {toasterError ? <ToasterError toggle={toasterError} /> : null}
      </ToasterWrapper>

      <Modal
        show={isOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {}}
      >
        <Modal.Header
          closeButton
          onClick={() => {
            toggleModalState(!isOpen);
          }}
        />
        <Modal.Body className="cardStyle row">
          <div className="modal-container">
            <h4>Beoordeel "{review.eventName}"</h4>
            <p>
              Help ons en je collega's door een beoordeling te geven over jouw
              ervaring.
            </p>

            <StarRatings
              rating={rating}
              changeRating={newRating => setRaing(newRating)}
              numberOfStars={5}
              starDimension="35px"
              starRatedColor="#05bacf"
              starHoverColor="#05bacf"
              starEmptyColor="#dcdcdc"
              name="rating"
            />

            <h6>Feedback</h6>
            <textarea
              placeholder="Vul hier je feedback in..."
              value={remark || ""}
              type="text"
              onChange={e => setRemark(e.target.value)}
            />
            <div className="button-container">
              <DefaultButton
                disabled={rating <= 0}
                text="Verstuur"
                icon="share"
                fn={postUserEventReview}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default EventReviews;
