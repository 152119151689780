export const USER_PREFERENCES_FETCH_ERROR = "USER_PREFERENCES_FETCH_ERROR";
export const USER_PREFERENCES_FETCH_SUCCESS = "USER_PREFERENCES_FETCH_SUCCESS";
export const USER_PREFERENCES_FETCH_START = "USER_PREFERENCES_FETCH_START";

export const DIET_OPTIONS_FETCH_ERROR = "DIET_OPTIONS_FETCH_ERROR";
export const DIET_OPTIONS_FETCH_SUCCESS = "DIET_OPTIONS_FETCH_SUCCESS";
export const DIET_OPTIONS_FETCH_START = "DIET_OPTIONS_FETCH_START";

export const USER_PREFERENCES_SAVE_ERROR = "USER_PREFERENCES_SAVE_ERROR";
export const USER_PREFERENCES_SAVE_SUCCESS = "USER_PREFERENCES_SAVE_SUCCESS";
export const USER_PREFERENCES_SAVE_START = "USER_PREFERENCES_SAVE_START";

export const userPreferencesFetchStarted = () => {
  return {
    type: USER_PREFERENCES_FETCH_START
  };
};

export const userPreferencesfetchFailed = () => {
  return {
    type: USER_PREFERENCES_FETCH_ERROR
  };
};

export const userPreferencesfetchSuccess = userPreferences => {
  return {
    type: USER_PREFERENCES_FETCH_SUCCESS,
    userPreferences: userPreferences
  };
};

export const dietOptionsFetchStarted = () => {
  return {
    type: DIET_OPTIONS_FETCH_START
  };
};

export const dietOptionsFetchFailed = () => {
  return {
    type: DIET_OPTIONS_FETCH_ERROR
  };
};

export const dietOptionsFetchSuccess = dietOptions => {
  return {
    type: DIET_OPTIONS_FETCH_SUCCESS,
    dietOptions: dietOptions
  };
};

//

export const userPreferencesSaveStarted = () => {
    return {
      type: USER_PREFERENCES_SAVE_START
    };
  };
  
  export const userPreferencesSaveFailed = () => {
    return {
      type: USER_PREFERENCES_SAVE_ERROR
    };
  };
  
  export const userPreferencesSaveSuccess = userPreferences => {
    return {
      type: USER_PREFERENCES_SAVE_SUCCESS,
      userPreferences: userPreferences
    };
  };