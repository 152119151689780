export const USER_VALIDATE_START = "USER_VALIDATE_START";
export const USER_VALIDATE_ERROR = "USER_VALIDATE_ERROR";
export const USER_VALIDATE_SUCCESS = "USER_VALIDATE_SUCCESS";
export const USER_RESET = "USER_RESET";

export const userValidateStarted = () => {
  return {
    type: USER_VALIDATE_START
  };
};

export const userValidateFailed = () => {
  return {
    type: USER_VALIDATE_ERROR
  };
};

export const userValidateSuccess = user => {
  return {
    type: USER_VALIDATE_SUCCESS,
    user: user
  };
};

export const reloadUserState = {
  type: USER_RESET
};
