import React, { Fragment } from "react";
import RobotBrokenImage from "../../assets/images/robotBroken";
import { useDispatch } from "react-redux"
import { reloadUserState } from "../../actions/userActions";
import NotificationPage from "../notification/NotificationPage";

const ErrorPage = () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="wrapper">
          <NotificationPage
            heading={"Probeer het later nog eens"}
            text={"503: Systeem momenteel niet beschikbaar."}
            navigate={() => {
              dispatch(reloadUserState)
            }}
            buttonText="Reload"
            buttonClass="fa fa-refresh"
          >
            <RobotBrokenImage />
          </NotificationPage>
      </div>
    </Fragment>
  );
};

export default ErrorPage;
