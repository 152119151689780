export const GetEvents = `/events/peryear`;
export const GetUserEvents = `/eventresponse/myevents/peryear`;
export const PostEvent = "/eventresponse/";
export const GetEventDetails = `/eventresponse/events/`;
export const GetEvent = "/events/";
export const GetEventReviews = "/eventreview/getreviews";
export const GetEventReview = "/eventreview/";
export const PostEventReview = "/eventreview/savereview";
export const PutEventReview = "/eventreview/updatereview";
export const GetDietOptions = "/userdietpreferences/getdietoptions/";
export const GetDietPreferences = "/userdietpreferences/getdietpreferences/";
export const SaveUserPreferences = "/userdietpreferences/savedietpreferences/";
export const AddToCalendar = "/calendar/addevent";
export const EmailRegistration = (code, email) =>
  `/email/registrationemail?code=${code}&email=${email}`;
  export const ValidateUser = "/users/validate";
