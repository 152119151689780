import { lazy } from "react";

const Events = lazy(() => import("../containers/events/Events.jsx"));
const MyEvents = lazy(() => import("../containers/events/MyEvents.jsx"));
const Event = lazy(() => import("../containers/events/Event.jsx"));
const Settings = lazy(() => import("../containers/settings/Settings.jsx"));
const ForbiddenPage = lazy(() =>
  import("../components/errorpages/ForbiddenPage")
);
const routes = [
  { path: "/", name: "Events", component: Events, exact: true },
  { path: "/events", name: "Events", component: Events, exact: true },
  { path: "/myevents", name: "MyEvents", component: MyEvents, exact: true },
  { path: "/settings", name: "Settings", component: Settings, exact: true },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: ForbiddenPage,
    exact: true
  },
  { path: "/event/:id", name: "Event", component: Event, exact: true }
];

export default routes;
