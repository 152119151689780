import { combineReducers } from "redux";
import eventsReducer from "./eventsReducer";
import eventReducer from "./eventReducer";
import myEventsReducer from "./myEventsReducer";
import eventReviewReducer from "./eventReviewReducer";
import userPreferencesReducer from "./userPreferencesReducer";
import userReducer from "./userReducer";
import loginReducer from "./loginReducer";

export default combineReducers({
  eventsReducer,
  eventReducer,
  myEventsReducer,
  eventReviewReducer,
  userPreferencesReducer,
  userReducer,
  loginReducer
});
