import { PENDING, LOADED, ERROR, LOADING } from "../constants/statusTypes";
import * as actions from "../actions/loginAction";

export const initialState = {
  loggedIn: { status: PENDING },
  deviceCode: { status: PENDING, data: null, expired: true },
  userCode: { status: PENDING, data: null },
  emailStatus: { status: PENDING, confirmed: false },
  userInput: false,
  botMessages: [
    {
      user: "betabot",
      message:
        "Hoi!Om ervoor te zorgen dat je je niet telkens opniew hoeft in te loggen moeten we jouw apparaat registeren.",
      reset: false
    },
    {
      user: "betabot",
      message:
        "Vul Hieronder je email adres in zodat we je instructies hiervoor kunnen toesturen."
    },
    {
      user: "betabot",
      message: "Maak je niet druk, het duurt maar een paar minuutjes.",
      reset: false
    }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGGED_IN:
      return { ...state, loggedIn: { status: LOADED } };
    case actions.LOGGED_IN_ERROR:
      return {
        ...state,
        loggedIn: { status: PENDING },
        emailStatus: { status: LOADED, confirmed: false },
        botMessages: [...state.botMessages, confirmedErrorMessage]
      };
    case actions.DEVICE_CODE_EXPIRED:
      return {
        ...state,
        deviceCode: {
          status: PENDING,
          data: state.deviceCode.data,
          expired: true
        }
      };
    case actions.DEVICE_CODE_FETCHED:
      return {
        ...state,
        deviceCode: { status: LOADED, data: action.deviceCode, expired: false }
      };
    case actions.DEVICE_CODE_ERROR:
      return {
        ...state,
        deviceCode: { status: ERROR, data: null, expired: true }
      };
    case actions.USER_CODE_FETCHED:
      return { ...state, userCode: { status: LOADED, data: action.userCode } };
    case actions.USER_CODE_ERROR:
      return { ...state, userCode: { status: ERROR, data: null } };
    case actions.USER_CODE_REFRESH:
      return { ...state, userCode: { status: PENDING, data: null } };
    case actions.EMAIL_CONFIRMED:
      return { ...state, emailStatus: { status: LOADED, confirmed: true } };
    case actions.EMAIL_STARTED:
      return { ...state, emailStatus: { status: LOADING } };
    case actions.EMAIL_SENT:
      return { ...state, emailStatus: { status: LOADING } };
    case actions.EMAIL_ERROR:
      return { ...state, emailStatus: { status: ERROR } };
    case actions.EMAIL_CONFIRMED_ERROR:
      return { ...state, emailStatus: { status: LOADED, confirmed: false } };
    case actions.MESSAGE_ADDED:
      return {
        ...state,
        userInput: true,
        botMessages: [
          ...state.botMessages,
          { user: "user", message: action.message },
          ...afterSubmitBotMessages
        ]
      };
    case actions.RESET_USER_INPUT:
      return { ...state, userInput: false };
    default:
      return state;
  }
};

const afterSubmitBotMessages = [
  {
    user: "betabot",
    message:
      "Dankje! Het kan een aantal minuutjes duren en vergeet je spam niet te checken!.",
    reset: false
  },
  {
    user: "betabot",
    message:
      "Kan het zijn dat je het verkeerde email adres hebt ingevuld? Klik dan op de onderestaande knop:",
    reset: true
  }
];

const confirmedErrorMessage = {
  user: "betabot",
  message:
    "Het lijkt erop dat jouw apparaat nog niet geregistreerd staat. Volg de gebruiksaanwijzingen in het mailtje om door te gaan!"
};
