import { PENDING, LOADED, LOADING, ERROR } from "../constants/statusTypes";
import * as actions from "../actions/userPreferencesActions";

export const initialState = {
  userPreferences: { status: PENDING, data: [] },
  dietOptions: { status: PENDING, data: [] }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_PREFERENCES_FETCH_START:
      return { ...state, userPreferences: { status: LOADING, data: [] } };
    case actions.USER_PREFERENCES_FETCH_SUCCESS:
      return {
        ...state,
        userPreferences: { status: LOADED, data: action.userPreferences }
      };
    case actions.USER_PREFERENCES_FETCH_ERROR:
      return { ...state, userPreferences: { status: ERROR, data: [] } };
    case actions.DIET_OPTIONS_FETCH_START:
      return { ...state, dietOptions: { status: LOADING, data: [] } };
    case actions.DIET_OPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        dietOptions: { status: LOADED, data: action.dietOptions }
      };
    case actions.DIET_OPTIONS_FETCH_ERROR:
      return { ...state, dietOptions: { status: ERROR, data: [] } };
    case actions.USER_PREFERENCES_SAVE_START:
      return { ...state, userPreferences: { status: LOADED, data: [] } };
    case actions.USER_PREFERENCES_SAVE_SUCCESS:
      return {
        ...state,
        userPreferences: { status: LOADED, data: action.userPreferences }
      };
    case actions.USER_PREFERENCES_SAVE_ERROR:
      return { ...state, userPreferences: { status: ERROR, data: [] } };
    default:
      return state;
  }
};
