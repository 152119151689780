import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterLogoutButton = ({ fn, name }) => {
  return (
    <div onClick={() => fn()}>
      <div className="icon-holder">
        <FontAwesomeIcon color="#2f353" icon={["fas", `sign-out-alt`]} />
      </div>

      <p>{name}</p>
    </div>
  );
};

export default FooterLogoutButton;
