import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterNavButton = ({ route, icon, name }) => {
  return (
    <NavLink to={route} key={name} activeClassName="active-link">
      <div className="icon-holder">
        <FontAwesomeIcon color="#2f353" icon={["fas", `${icon}`]} />
      </div>

      <p>{name}</p>
    </NavLink>
  );
};

export default FooterNavButton;
