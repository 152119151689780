import { PENDING, LOADED, LOADING, ERROR } from "../constants/statusTypes";
import * as actions from "../actions/eventsAction";

export const initialState = {
  myEvents: { status: PENDING, data: [] }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.MY_EVENTS_FETCH_START:
      return { ...state, myEvents: { status: LOADING, data: [] } };
    case actions.MY_EVENTS_FETCH_SUCCESS:
      return { ...state, myEvents: { status: LOADED, data: action.myEvents } };
    case actions.MY_EVENTS_FETCH_ERROR:
      return { ...state, myEvents: { status: ERROR, data: [] } };
    default:
      return state;
  }
};
